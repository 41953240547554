import React from 'react';

function ServicesFooter() {
  const workWithUs = () => {
    document.getElementById('work-with-us').click();
  };

  return (
    <>
      <div className="fit-width services-footer">
        <div className="header">
          You&apos;ve got big dreams.
          <br />
          We&apos;ll help you achieve them
        </div>
        <button
          id="work-with-us"
          onClick={() => { workWithUs(); }}
          className="btn btn-sm custom-btn button"
        >
          Let&apos;s work together
        </button>
      </div>
    </>
  );
}

export default ServicesFooter;
