import React from 'react';

function FullBackgroundImage({ image, header, subheader }) {
  return (
    <>
      <div
        className="services-full-section full-width"
      >
        <div
          className="image"
          style={{
            backgroundImage: `url(${image})`,
            backgroundPosition: 'top center',
          }}
        />
        <div className="text-container">
          <div className="content">
            <h2 className="mb-24 header">
              {header}
            </h2>
            <p className="subheader">
              {subheader}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default FullBackgroundImage;
