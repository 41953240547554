import React from 'react';
import graphicLines from '../../assets/images/gifs/graphic-lines.png';

function HeadingSection({ video1, video2, video3 }) {
  return (
    <div className="fit-width mb-120">
      <div
        className="services-heading"
        style={{
          backgroundImage: `url(${graphicLines})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      >
        <div className="wrapper wrapper1">
          <video
            autoPlay
            loop
            muted
            className="video"
          >
            <source src={video1} type="video/mp4" />
          </video>
          <div className="caption caption1 -teal">
            General Counsel
          </div>
        </div>
        <div className="wrapper wrapper2">
          <video
            autoPlay
            loop
            muted
            className="video"
          >
            <source src={video2} type="video/mp4" />
          </video>
          <div className="caption caption2">
            Legal Operations Manager
          </div>
        </div>
        <div className="wrapper wrapper3">
          <video
            autoPlay
            loop
            muted
            className="video"
          >
            <source src={video3} type="video/mp4" />
          </video>
          <div className="caption caption3 -purple">
            Partner
          </div>
        </div>
        <div className="text-container">
          <h2 className="mb-24">
            Expert services to
            <br />
            enable digital
            <br />
            transformation
          </h2>
          <p>
            We help you prepare and thrive for the new normal.
          </p>
        </div>
        <div className="bgcaption marketing">
          Marketing
        </div>
        <div className="bgcaption cfo">
          CFO
        </div>
        <div className="bgcaption finance">
          Finance
        </div>
        <div className="bgcaption legal">
          Legal Engineers
        </div>
        <div className="bgcaption counsel">
          Senior Counsel
        </div>
        <div className="bgcaption ceo">
          CEO
        </div>
        <div className="bgcaption associate">
          Senior Associate
        </div>
      </div>
    </div>
  );
}

export default HeadingSection;
