import React from 'react';
import quoteLogo from '../../assets/images/icons/quote-dark.svg';
import graphicLines from '../../assets/images/gifs/graphic-lines.png';

function QuoteContainer({ authorName, authorAvatar, quote, companyPosition, companyLogo }) {
  return (
    <>
      <div className="fit-width">
        <div
          className="services-quote-container"
          style={{
            backgroundImage: `url(${graphicLines})`,
            backgroundPosition: 'right',
          }}
        >
          <img src={authorAvatar} alt={authorName} className="avatar" />
          <div className="text-container">
            <img src={quoteLogo} alt="quote-dark" />
            <div className="text">
              {quote}
            </div>
            <div />
            <div className="author-details">
              <div className="name">{authorName}</div>
              <div className="position">{companyPosition}</div>
              <img src={companyLogo} alt={companyPosition} className="logo" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default QuoteContainer;
