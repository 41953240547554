import React from 'react';

import Layout from '../components/layout/Layout';
import HeadingSection from '../components/services/HeadingSection';
import FullBackgroundImage from '../components/services/FullBackgroundImage';
import QuoteContainer from '../components/services/QuoteContainer';
import ServicesFooter from '../components/services/ServicesFooter';
import LatestNews from '../components/news/LatestNews';
import HeaderVideo1 from '../assets/videos/lawadvisor-ventures--loop-01.mp4';
import HeaderVideo2 from '../assets/videos/lawadvisor-ventures--loop-02.mp4';
import HeaderVideo3 from '../assets/videos/lawadvisor-ventures--loop-03.mp4';
import fullImage1 from '../assets/images/StockPhotos/optimized-photos/services-page/lawadvisor-services-01.jpg';
import fullImage2 from '../assets/images/StockPhotos/optimized-photos/services-page/lawadvisor-services-02.jpg';
import fullImage3 from '../assets/images/StockPhotos/optimized-photos/services-page/lawadvisor-services-03.jpg';
import fullImage4 from '../assets/images/StockPhotos/optimized-photos/services-page/lawadvisor-services-04.jpg';
import brennanOng from '../assets/images/brennan-ong.jpeg';
import lawadvisorVenturesLogo from '../assets/images/logos/la_ventures_logo/LA-Ventures-Logo (Placeholder) (Dark).png';

function Services() {
  return (
    <Layout
      title="Services"
      description="We help you prepare and thrive for the new normal."
      image="/metaimages/meta-image-la-ventures.png"
    >
      <HeadingSection video1={HeaderVideo1} video2={HeaderVideo2} video3={HeaderVideo3} />
      <FullBackgroundImage
        image={fullImage1}
        header="Here to help, every step of the way"
        subheader="Ensure the right outcomes and realise value faster with proven methodologies, tools, and leverage expertise informed by thousands of projects."
      />
      <FullBackgroundImage
        image={fullImage2}
        header="Drive adoption"
        subheader="Speed up platform adoption across your organisation."
      />
      <FullBackgroundImage
        image={fullImage3}
        header="Accelerate time to value"
        subheader="Get it implemented and rolled-out right, the first time. We live and breathe your people and processes."
      />
      <FullBackgroundImage
        image={fullImage4}
        header="Improve user experience"
        subheader="Uncover ways to work better by becoming more tech-enabled and improving user and customer experiences."
      />
      <QuoteContainer
        authorAvatar={brennanOng}
        authorName="Brennan Ong"
        quote="LawAdvisor understands that digital transformation is secondary to, and an outcome of, business transformation. This is why we've recruited best-in-class domain experts who bring knowledge from thousands of projects, to help our clients achieve their desired business outcomes."
        companyPosition="LawAdvisor Ventures, CEO"
        companyLogo={lawadvisorVenturesLogo}
      />
      <ServicesFooter />
      <LatestNews className="job-post-section fit-width" />
    </Layout>
  );
}

export default Services;
